var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","tag":"section"}},[_vm._l((_vm.teams),function(t){return _c('v-card',{key:t._id},[_c('v-card-text',[_c('h2',[_vm._v(_vm._s(t.name))]),_c('v-data-table',{attrs:{"items":t.acl,"headers":[
          { text: 'Funktion', value: 'type', sortable: false },
          { text: 'Person', value: 'person', sortable: false },
          { text: 'E-Mail', value: 'email', sortable: false }
        ],"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
        var item = ref.item;
return [(item.type === 'team')?_c('span',[_vm._v("Mannchaftsverantwortlicher")]):_vm._e(),(item.type === 'club')?_c('span',[_vm._v("Vereinsverantwortlicher")]):_vm._e()]}},{key:"item.person",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("person")(item))+" ")]}},{key:"item.email",fn:function(ref){
        var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.email.join('<br>'))}})]}}],null,true)})],1)],1)}),_c('v-card',[_c('v-card-text',[_c('h2',[_vm._v("Begegnungen:")]),_c('v-data-table',{attrs:{"items":_vm.eventjudges,"headers":[
          { text: 'Begegnung am', value: 'startDate', sortable: false },
          { text: 'Gegner', value: 'gegner', sortable: false },
          { text: 'Typ', value: 'type', sortable: false },
          { text: 'Kampfrichter', value: 'name', sortable: false },
          { text: 'E-Mail', value: 'mail', sortable: false }
        ],"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.startDate",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(_vm._f("dateformat")(item.startDate,'DD.MM.YYYY HH:mm')))]}},{key:"item.gegner",fn:function(ref){
        var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.teams.filter(function (t) { return t.team._id !== _vm.team; }).map(function (t) { return t.team.name; }).join('<br />'))}})]}},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [(item.judge.person)?[_vm._v(_vm._s(_vm._f("person")(item.judge.person)))]:(item.judge.club)?[_vm._v("Vereinseinsatz:"),_c('br'),_vm._v(_vm._s(item.judge.club.name))]:_vm._e()]}},{key:"item.type",fn:function(ref){
        var item = ref.item;
return [(item.judge.type === 'e1')?[_vm._v("Oberkampfrichter E1")]:_vm._e(),(item.judge.type === 'e2')?[_vm._v("Neutraler Kampfrichter E2")]:_vm._e(),(item.judge.type === 'e3')?[_vm._v("Heimkampfrichter E3")]:_vm._e(),(item.judge.type === 'e4')?[_vm._v("Gastkampfrichter E4")]:_vm._e(),(item.judge.type === 'dh')?[_vm._v("Heimkampfrichter D")]:_vm._e(),(item.judge.type === 'dg')?[_vm._v("Gastkampfrichter D")]:_vm._e()]}},{key:"item.mail",fn:function(ref){
        var item = ref.item;
return [(item.judge.person)?[_c('span',{domProps:{"innerHTML":_vm._s(item.email.map(function (m) { return m.value; }).join('<br />'))}})]:(item.judge.club)?[_c('span',{domProps:{"innerHTML":_vm._s(item.email.map(function (m) { return m.value + ' (' + m.p.familyName + ', ' + m.p.givenName + ')'; }).join('<br />'))}})]:_vm._e()]}}])})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }